import React from 'react'
import { Layout, Stack, Main } from '@layout'
import { Link, IconButton, Text, Heading } from 'theme-ui'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import {
    FaGithub,
    FaLinkedinIn,
    FaTwitter,
    FaEnvelope,
    FaInstagram,
  } from 'react-icons/fa'


const styles = {
    heading: {
      color: `omega`,
      fontWeight: `bold`
    },
    running: {
      color: `omegaDark`,
      fontWeight: `normal`,
      maxWidth: [`full`, null, `2/3`]
    }
  }
const ContactPage = (props) => {
    return (
        <Layout {...props}>
            <Seo title='Contact Babu Srinivasan' />
            <Stack>
            <Main>
                <Divider/>
                <Heading variant='h2' sx={styles.heading}>
                Contact Babu Srinivasan
                </Heading>
                <Text>
                    <IconButton variant='simple' role='presentation'>
                        <FaEnvelope/>
                    </IconButton>
                    <Link href='mailto: babu@broken-ear.io'>
                    babu@broken-ear.io
                    </Link>
                </Text>
                <Text>
                    <IconButton variant='simple' role='presentation'>
                        <FaLinkedinIn/>
                    </IconButton>
                    <Link href='https://www.linkedin.com/in/babu-srinivasan'>
                    www.linkedin.com/in/babu-srinivasan
                    </Link>
                </Text>
                <Text>
                    <IconButton variant='simple' role='presentation'>
                        <FaTwitter/>
                    </IconButton>
                    <Link href='https://twitter.com/babusrinivasan0'>
                    https://twitter.com/babusrinivasan0
                    </Link>
                </Text>
                <Text>
                    <IconButton variant='simple' role='presentation'>
                        <FaGithub/>
                    </IconButton>
                    <Link href='https://github.com/babu-srinivasan'>
                    https://github.com/babu-srinivasan
                    </Link>
                </Text>
                <Text>
                    <IconButton variant='simple' role='presentation'>
                        <FaInstagram/>
                    </IconButton>
                    <Link href='https://www.instagram.com/babusrinivasan10/'>
                    https://www.instagram.com/babusrinivasan10/
                    </Link>
                </Text>
            </Main>
            </Stack>
        </Layout>
    )
}

export default ContactPage